<template>
    <div class="app-box">
      <NavTop></NavTop>
      <div class="share-views">
          <!-- 文件取件码---S -->
          <div class="pucodePage"  v-show="isCheckPucode">
              <div class="pucodeBox">
                  <!-- 分享文件-分享成功---E -->
                  <img class="avatar" :src="auth_logo">
                  <span class="desc">{{ auth_name }}的私密分享</span>
                  <el-input placeholder="请输入取件码" v-model="pucode"></el-input>
                  <span class="btn" @click="handlePucode()">取件</span>
                  <!-- <a class="link" target="_blank" href="https://wwwtest.feimaoyun.com/vd/MDAwMDAwMDAwMH7Qfq6DjYacs9yH3Yiqu5uFc3Ce">不会取件？查看视频教程</a> -->
                  <span class="linkDesc">该链接由用户：{{ auth_name }}自行加密创建，并不代表本站立场</span>
                  <img class="banner" :src="adData.cover" @click="goAdBanner()">
              </div>
          </div>
          <!--  -->
          <div class="shareListDetail-box">
              <div class="shareListDetail" v-loading="loading" v-show="!isCheckPucode">
                  <div class="sd_top">
                      <div class="left">
                          <img :src="$utils.getPng('shareDetail_fileIcon3')">
                          <div class="fileDesc">
                              <div class="collectBox">
                                <!-- 文件名和后缀名 -->
                                <span class="filename">
                                  {{ gatherInfo.gather_name }}
                                </span>
                                <!-- 收藏图标 -->
                                <svg-btn @click="collect" class="collect" :icon-class="collectShow == 1?'icon-collect-two':'icon-collect-one'"></svg-btn>
                              </div>
                              <div class="desc">
                                  <span>创建日期：{{gatherInfo.create_time}}</span>
                                  <span>本文件由网友：{{uname}} 自行上传，并不代表本站立场</span>
                              </div>
                          </div>
                      </div>
                      <div class="right">
                      <fm-button size="medium" round type="info" plain icon="icon-tool-copy" @click="copyUrl">复制链接</fm-button>
                      <!-- <fm-button :disabled="isShowMain === 1" type="primary" size="medium" round @click="setCollect">
                          {{ is_collect=='1' ? '已收藏' : '收藏合集' }}
                      </fm-button> -->
                      </div>
                  </div>
                  <div class="content">
                      <div class="c-top">
                          <span class="title">共 {{total}} 项文件</span>
                          <div class="handleItem">
                              <el-input v-model="params.keywords" placeholder="搜索分享文件"  @keyup.enter.native="search" >
                                  <svg-btn class="icon-clear" v-show="params.keywords!=''" @click="clearValue" slot="suffix" icon-class="icon-ipt-clear"></svg-btn>
                                  <svg-btn slot="suffix" icon-class="icon-ipt-search" @click="search"/>
                              </el-input>
                              <span class="line"></span>
                              <div class="screenBtn">
                                  <fm-button icon="icon-cloud-screen" type="info" text size="small"></fm-button>
                                  <div class="screenBox">
                                      <div @click="sort(item.id)" v-for="item in screenList" :key="item.id" :class="{'curScreen':item.id==screenId}">
                                          <svg-btn icon-class="icon-cloud-check"/>
                                          <span >{{ item.name }}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="c-list" v-infinite-scroll="gatherInfoList"
                          ref="scrollBox"
                          infinite-scroll-distance="50"
                          infinite-scroll-immediate="false"
                          @scroll="doScroll()" v-show="isShowMain === 0 && total!=0">
                          <div class="item-hover" v-for="(item,index) in gather_files" :key="index">
                              <div class="item">
                                  <img class="icon" :src="$utils.fileIcon(item.ext)"/>
                                  <div class="desc">
                                      <span class="name">{{item.file_name}}{{item.extension}}</span>
                                      <span class="info">
                                          <font class="size">大小：{{item.file_size}}</font> <font>时间：{{item.file_time}}</font>
                                      </span>
                                      <div style="display: flex">
                                        <div class="itemFilesTools" @click="goDownPage(item)">
                                          <svg-btn class="down-svg" icon-class="icon-down-g" />
                                          <span>立即下载</span>
                                      </div>
                                      <div class="itemFilesTools" style="margin-left: 16px;" @click="collectFile(item,index)">
                                          <svg-btn class="collectList" :icon-class="item.is_collect == 1?'icon-collect-two':'icon-collect-three'"></svg-btn>
                                          <span>{{ item.is_collect == 1?'取消收藏':'收藏' }}</span>
                                      </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <span class="nomore" v-if="isOver && total!=0">没有更多了</span>
                      </div>
                      <div class="noData" v-show="isShowMain === 0 && total==0">
                          <img :src="$utils.getPng('web4/file_default')">
                          <span>暂无文件</span>
                      </div>
                      <div class="noShareList" v-show="isShowMain === 1">
                          <img :src="$utils.getPng('icon_warn_big')">
                          <span>此链接已被上传者取消分享无法访问</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- 取消收藏提示框 -->
      <TitleDialog
        ref="TitleDialogRef"
        :is-show="cloCollectShow"
        :title="collectObj.title"
        :text="collectObj.text"
        @handleClose="onCollectClose"
        @handleConfirm="onCollectConfirm"
      >
      </TitleDialog>
      <div class="toTop-div" v-show="showTop" @click="scrollToTop">
          <svg-btn icon-class="icon-toTop" style="width: 24px;height: 24px;"></svg-btn>
      </div>
    </div>
  </template>
  
  <script>
  import NavTop from "@/components/NavTop";
  import TitleDialog from "@/components/Dialog/TitleDialog.vue";
  import {checkPucode,gatherInfoList} from "@/utils/apiList/share"
  import { handleCollect } from "@/utils/api/utils";
  import {
  addCollect,
  delCollect,
} from "@/utils/apiList/downpage";
  export default {
      components: {
          NavTop,
          TitleDialog,
      },
      data(){
          return{
            cLoading:false,
              isCheckPucode:false,
              adData:{},
              pucode:'',
              auth_logo:'',
              auth_name:'',
              loading:true,
              isShowMain:-1,
              screenList:[
                  { id: 0, name: "最新" },
                  { id: 1, name: "最早" },
                  { id: 2, name: "最大" },
                  { id: 3, name: "最小" },
              ],
              screenId:0,
              params:{
                  gather_code:'',
                  file_name:'',
                  gather_id:'',
                  sort:1,//	最新最早排序，0最早，1最新
                  page:0,
                  limit:20,
                  fetch_code:'',
                  size_sort:'',//文件大小排序1最大，0最小
                  keywords:''
              },
              gatherInfo:{},
              gather_files:[],
              total:0,
              uname:'',
              collect_id:'',
              share_url:'',
              showCollectionSuccess:false,
              is_collect:'0',
              isOver: false,
              showTop:false,
              collectShow:0, // 判断收藏图标状态
              cloCollectShow:false, //取消收藏弹窗是否显示
              collectObj: { //取消收藏弹窗文字内容
                title: "提示",
                text: "确定取消收藏该文件吗？",
              },
              typeStatus:1,//1是合集，2是文件
              fileId:'',//文件ID
              fileIndex:'',//文件下标
          }
      },
      created(){
          this.params.gather_code = this.$route.params.code;
          this.checkPucode()
      },
      watch:{
      },
      methods:{
        //合集点击收藏图标
       collect: _.debounce(
          function () {
            if (!this.$utils.getCookie(this.$glb.fmCookieName)) {
                  this.goLogin()
                  return
            }
            if(this.collectShow == 0){
              //如果此时是关闭状态
              addCollect({
                gathers_id:this.gatherInfo.gather_id,
              }).then(res =>{
                if(res.status == 1){
                  this.collectShow = 1
                  this.$toast("收藏合集成功", 1);
                  // 为了体验，不及时更新页面
                  // this.gatherInfoList()
                }else if(res.status == 10020 || res.status == 5000){
                }else{
                  this.$toast(res.msg, 2);
                }
              })
            }else{
              //如果此时是打开状态
              this.fileId = '' 
              this.collectObj.text = '确定取消收藏该合集吗？'
              this.cloCollectShow = true;
            }
          },
          500,
          {
            leading: true, //指定在延迟开始前调用
            trailing: false, //指定在延迟结束后调用
          }
        ),
        //确定取消收藏弹窗
        onCollectConfirm(){
          let data = {}
          if(this.fileId == ''){
            data = {
              gathers_id:this.gatherInfo.gather_id,
            }
          }else{
            data = {
              file_id:this.fileId,
            }
          }
          delCollect(data).then(res =>{
            if(res.status == 1){
              if(this.fileId == ''){
                this.collectShow = 0
              }else{
                this.gather_files[this.fileIndex].is_collect = 0
              }
              this.onCollectClose()
              // 为了体验，不及时更新页面
              // this.gatherInfoList()
            }else{
              this.$toast(res.msg, 2);
            }
          })
        },
        //关闭取消收藏弹窗
        onCollectClose() {
          this.fileId = ''
          this.fileIndex = ''
          this.cloCollectShow = false;
        },
        //文件点击收藏图标
        collectFile: _.debounce(
          function (item,index) {
            if (!this.$utils.getCookie(this.$glb.fmCookieName)) {
                  this.goLogin()
                  return
            }
            if(item.is_collect == 0){
              //如果此时是关闭状态
              addCollect({
                file_id:item.file_id,
              }).then(res =>{
                if(res.status == 1){
                  this.gather_files[index].is_collect = 1
                  this.$toast("收藏文件成功", 1);
                  // 为了体验，不及时更新页面
                  // this.gatherInfoList('pull')
                }else if(res.status == 10020 || res.status == 5000){
                }else{
                  this.$toast(res.msg, 2);
                }
              })
            }else{
              //如果此时是打开状态
              this.fileId = item.file_id
              this.fileIndex = index
              this.collectObj.text = '确定取消收藏该文件吗？'
              this.cloCollectShow = true;
            }
          },
          500,
          {
            leading: true, //指定在延迟开始前调用
            trailing: false, //指定在延迟结束后调用
          }
        ),
        sort(id){
            this.screenId=id
            switch (id) {
                case 0:
                    this.params.sort=1
                    this.params.size_sort=''
                break;
                case 1:
                    this.params.sort=0
                    this.params.size_sort=''
                break;
                case 2:
                    this.params.sort=''
                    this.params.size_sort=1
                break;
                case 3:
                    this.params.sort=''
                    this.params.size_sort=0
                break;
                default:
                break;
            }
            this.params.page=1
            this.gatherInfoList(1)
        },
        //检测是否需要输入取件码
        checkPucode(){
            checkPucode({code:this.params.gather_code}).then((res)=>{
                if(res.status=='1'){
                    if(res.data.if_check_pucode=='1'){
                        this.isCheckPucode=true
                        this.auth_logo=res.data.auth_logo
                        this.auth_name=res.data.auth_name
                        this.adData=res.data.ad_list[0]
                        //是否自动填充
                        if (this.$route.query.pucode) {
                            this.pucode = this.$route.query.pucode;
                        }
                    }else{
                        this.isCheckPucode=false
                        this.gatherInfoList()
                    }
                }else{
                    this.$toast(res.msg, 2)
                }
            })
        },
        handlePucode(){
            if(this.pucode==''){
                this.$toast('取件码不能为空', 2)
                return
            }
            this.gatherInfoList(1);
        },
          gatherInfoList: _.debounce(
              function (type = 0) {
                  if (type == 1) {
                      this.gather_files = [];
                      this.params.page = 0;
                  } else {
                      if (this.isOver) return;
                  }
                  this.params.page += 1;
                  this.isOver = false;
                  this.params.fetch_code = this.pucode
                  gatherInfoList(this.params)
                  .then((res) => {
                      if (res.status == "1") {
                          this.isCheckPucode = false;
                          this.uname=res.data.user.uname
                          this.share_url=res.data.share_url
                          this.collectShow = res.data.is_collect

                          this.is_collect=res.data.is_collect
                        this.collect_id = res.data.collect_id
                          this.gatherInfo=res.data.gather
                          this.loading=false
                          if(res.data.code=="80001"){//取消分享的链接
                              this.isShowMain=1
                          }else{
                              this.isShowMain=0
                              this.total=res.data.total
                              if (res.data.gather_files.length < this.params.limit) this.isOver = true;
                              if (this.params.page == 1) {
                                  this.gather_files=res.data.gather_files
                              } else {
                                  this.gather_files = this.gather_files.concat(res.data.gather_files);
                              }
                          }
                      } else if(res.data.status=='5000'){
                          this.$toast(res.msg, 2);
                      }else{
                          this.$toast(res.msg, 2)
                      }
                  })
                  .catch((err) => {});
              },
              50,
              {
                  leading: true, //指定在延迟开始前调用
                  trailing: false, //指定在延迟结束后调用
              }
          ),
          async setCollect() {
            if (this.cLoading || this.isShowMain == 1) return
            this.cLoading = true
            let act, id
            if (this.is_collect == '1') {
              act = '0'
              id = this.collect_id
            } else {
              act = '1'
              id = this.gatherInfo.gather_id
            }
            let res = await handleCollect({
              action: act,
              type: 'folder',
              id
            })
            if (res.state) {
              this.is_collect = act
              this.gatherInfoList(1)
            } else if (res.msg) {
              this.$toast(res.msg, 2)
            }
            this.cLoading = false
          },
          copyUrl(){
              this.$copyText(this.share_url).then((e) => {
                  this.$toast(this.gatherInfo.good_count=='1' ? "复制链接及取件码成功" : "复制链接成功", 1)
              },function (e) {}
              );
          },
          search(){
              if (!this.$utils.getCookie(this.$glb.fmCookieName)) {
                  this.goLogin()
                  return
              }
              this.params.page=1
              this.gatherInfoList(1)
          },
          goAdBanner(){
              window.open(this.adData.link)
          },
          //去下载页
          goDownPage(item){
              if (!this.$utils.getCookie(this.$glb.fmCookieName)) {
                  this.goLogin()
              }else{
                  //合集打开下载A页面不需要检测取件码
                  if(item.good_count == '1'){
                      sessionStorage.setItem('isFromShareList',1)
                      window.open("/s/" + item.fshort+ "?" +"pucode=" + item.go_url + "#取件码：" + item.go_url)
                  }else{
                      sessionStorage.setItem('isFromShareList',1)
                      window.open("/s/" + item.fshort)
                  }
                  setTimeout(()=>{
                      sessionStorage.removeItem("isFromShareList")
                  },0)
              }
          },
          goLogin(){
              let path = this.$route.fullPath.toString().slice(1);
              this.$router.push({ path: "/login", query: { path: path } });
              return;
          },
          scrollToTop() {
              let scrollEl = this.$refs.scrollBox;
              scrollEl.scrollTop = 0;
          },
          doScroll(){
              var scrollEl = this.$refs.scrollBox;
              this.showTop = scrollEl.scrollTop > 50;
          },
          clearValue() {
              this.params.keywords = "";
              this.search();
          },
      }
  }
  </script>
  
  <style lang="scss">
    @import "./index.scss";
    .el-loading-mask{
      z-index: 1 !important;
    }
  </style>
  