<template>
  <div class="fmCloud" @mousemove.prevent="selectItems">
    <div class="fixed-top" :style="`width:${$store.state.appRightWidth}px`">
      <TabList
        ref="tabList"
        :placeholder="$t('Collection.SearchCollections')"
        curId="1"
        :gatherNum="$store.state.gathersNum"
        @setNav="setNav"
        @searchList="searchList"
      ></TabList>
      <div class="file-type">
        <div class="search-result" v-if="shareParams.keywords == ''">
          <el-tooltip
            class="item r-tooltip"
            effect="dark"
            :content="$t('Collection.AllSet')"
            placement="top"
          >
            <span class="all" style="font-weight: 500" @click="resetRouter"
              >{{ $t('Collection.AllSet') }}</span
            >
          </el-tooltip>
          <div class="total">
            {{ total }}
          </div>
        </div>
        <div class="search-result" v-if="shareParams.keywords !== ''">
          <el-tooltip
            class="item r-tooltip"
            effect="dark"
            :content="$t('Collection.AllSet')"
            placement="top"
          >
            <span class="all" @click="resetRouter">{{ $t('Collection.AllSet') }}</span>
          </el-tooltip>
          <div class="r-search">
            <svg-btn icon-class="icon-file-direction" />
             <div class="r-search-tit">
              {{$utils.searchTitSlice(shareParams.keywords)}}
            </div>
          </div>
        </div>
        <div class="fileClass">
          <div class="multiple-operation" v-if="checkedShares.length > 0">
            <div @click="isCancelShare()">
              <svg-btn icon-class="icon-share3" />
              <span> {{ $t(`Collection.uncollect`) }}</span>
            </div>
          </div>
          <div class="screenBt" style="margin-right: 4px">
            <svg-btn class="screen" icon-class="icon-option" />
            <div class="screenBox">
              <div
                @click="changeSort(item.id)"
                v-for="item in screenList"
                :key="item.id"
                :class="{
                  curScreen: item.id == screenId || item.id1 == screenId
                }"
              >
                <svg-btn icon-class="icon-cloud-check" />
                <span>{{ item.name }}</span>
              </div>
            </div>
            <!-- 为了适配悬浮而创建的子类 -->
            <div class="lineTips"></div>
          </div>
          <span class="line"></span>
          <fm-button
            class="icon-refresh"
            @click="refresh"
            icon="icon-refresh"
            type="info"
            text
            size="small"
          ></fm-button>
        </div>
      </div>
    </div>
    <div
      class="file-content"
      v-infinite-scroll="getShareList"
      ref="scrollBox"
      infinite-scroll-distance="50"
      infinite-scroll-immediate="false"
      @scroll="scrollBoxFun()"
      v-loading="loading"
      @mousedown="handleMouseDown($event, -1)"
      @mouseup.prevent="endSelection"
    >
      <div class="tab-th" v-show="total != 0">
        <div class="selectAll">
          <div @click="allCheck == 2 ? cancelCheckedVal() : setCheckedVal()">
            <svg-btn
              :icon-class="
                allCheck == 2
                  ? 'icon-file-check'
                  : allCheck == 0
                  ? 'icon-file-uncheck-'+$store.state.theme
                  : 'icon-file-checks'
              "
            >
            </svg-btn>
          </div>
          <span>{{ $t('Collection.SetName') }}</span>
        </div>
        <!-- 项目数 表头 -->
        <div
          class="lineup2"
          @click="changeSort(screenId == 2 ? 3 : 2)"
        >
          <span>{{ $t('setPage.NumberEntries') }}</span>
          <img
            v-show="screenId == 2 || screenId == 3"
            :src="
              screenId == 2
                ? $utils.getPng('web4/icon_order_descending')
                : $utils.getPng('web4/icon_order_ascending')
            "
          />
        </div>
        <!-- 所属用户 表头 -->
        <div class="lineup5" style="cursor: auto;">
          <span>{{$t('Collection.owningUser')}}</span>
        </div>
        <!-- 收藏时间 表头 -->
        <div
          class="lineup3"
          @click="changeSort(screenId == 0 ? 1 : 0)"
        >
          <span>{{ $t('Collection.CollectionTime') }}</span>
          <img
            v-show="screenId == 0 || screenId == 1"
            :src="
              screenId == 0
                ? $utils.getPng('web4/icon_order_descending')
                : $utils.getPng('web4/icon_order_ascending')
            "
          />
        </div>
      </div>
      <div class="file-list" v-if="shareList.length > 0">
        <div
          class="file-item-hover"
          v-for="(item, index) in shareList"
          :key="index"
          @click="setCollectInfo(item,index)"
          @mousedown="handleMouseDown($event, index ,item)"
          @mouseenter="handleMouseenter($event, index)"
          @mouseleave="handleMouseleave($event, index)"
          :class="[
            { hoverItem: item.isHover || checkedShares.indexOf(index) > -1 },
          ]"
          @dblclick="viewUrl(item,index)"
        >
          <a-dropdown :trigger="dropdown" @visibleChange="visibleChange">
            <div class="file-item">
              <div class="lineup1">
                <div class="icon_checks" @click.stop @dblclick.stop>
                  <svg-btn
                    class="icon_checks"
                    @click.prevent="chooseFile(index)"
                    :icon-class="
                      checkedShares.indexOf(index) > -1
                        ? 'icon-file-check'
                        : 'icon-file-uncheck-'+$store.state.theme
                    "
                  ></svg-btn>
                </div>
              <div class="openFoldername">
                <div class="file_icon">
                  <img class="icon" @error="onError($event, item)"
                        :src="item.showPlay ? item.ext_icon : $utils.fileIcon(item.extension)"/>
                </div>
                <el-tooltip open-delay="1000" popper-class="item list-tooltip" effect="dark" :content="item.name" placement="bottom">
                  <span class="name" style="min-width: 0;">{{ item.name }}</span>
                </el-tooltip>
                <div class="newBox" v-if="item.is_update == 1">{{ $t('Collection.New')  }}</div>
              </div>
              </div>
              <div @dblclick.stop class="tools-box" v-if="item.isHover || checkedShares.indexOf(index) > -1 || hoverIndex === index">
                <div class="itemFilesTools" v-show="checkedShares.length < 2">
                  <template>
                    <div class="act" @click.stop="viewUrl(item,index)">
                      <el-tooltip open-delay="1000"
                        popper-class="item list-tooltip"
                        effect="dark"
                        content="查看"
                        placement="bottom"
                      >
                        <svg-btn icon-class="icon-view"></svg-btn>
                      </el-tooltip>
                    </div>
                    <div class="act" @click.stop="copyUrl(item,index)">
                      <el-tooltip open-delay="1000"
                        popper-class="item list-tooltip"
                        effect="dark"
                        content="复制"
                        placement="bottom"
                      >
                        <svg-btn icon-class="icon-share2"></svg-btn>
                      </el-tooltip>
                    </div>
                    <div class="act">
                      <el-dropdown placement="bottom-start"
                        @visible-change="changeVisible($event, item)"
                        @command="(command) => handleCommand(command, item)"
                      >
                        <div>
                          <svg-btn icon-class="icon-cloud-more"></svg-btn>
                        </div>
                        <el-dropdown-menu
                          class="moreAct"
                          slot="dropdown"
                          :class="isOpen ? '' : 'isOpen1'"
                        >
                          <el-dropdown-item command="a"
                            >
                            <span>{{ $t(`Collection.uncollect`) }}</span>
                            </el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </div>
              </div>
              <div style="width: 36px;" v-else></div>

               <!-- 项目数 列表 -->
              <div class="lineup2"><span>{{item.file_count}}</span></div>

              <!-- 所属用户 列表 -->
              <div class="lineup5"><span>{{item.nickname}}</span></div>

              <!-- 收藏时间 列表 -->
              <div class="lineup3"><span>{{item.create_time}}</span></div>
            </div>
            <template #overlay>
              <a-menu class="menu-a" :class="isOpen2 ? 'menu-b':''">
                <a-menu-item v-show="!isSelectMore"
                  @click="rightHandle(menu_item.id, item,index)"
                  v-for="menu_item in list1"
                  :key="menu_item.id"
                  class="me-item"
                  >{{ $t(menu_item.name) }}
                </a-menu-item>
                <a-menu-item  @click="rightHandle(3, item,index)"  class="me-item"> 
                  {{ $t(`Collection.uncollect`) }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <div
          v-show="showFlag == 1"
          class="selection-box"
          :style="{
            left: selectionBox.left + 'px',
            top: selectionBox.top + 'px',
            width: selectionBox.width + 'px',
            height: selectionBox.height + 'px',
          }"
        ></div>
        <div class="isOver" v-if="isLoading2" v-loading="isLoading2"></div>

        <div class="isOver" v-if="isOver">{{ $t('cloud.noMore') }}</div>
      </div>
      <div class="file-default" v-if="!loading && shareList.length == 0">
        <img :src="$utils.getPng('web4/file_default')" />
        <div class="search"  v-if="shareParams.keywords==''">
          <span>{{ $t('Collection.NoFavoriteSet') }}</span><span>{{ $t('Collection.Texttwo') }}</span>
        </div>
        <div class="search" v-else>
          <span>{{ $t('setPage.NoToCollection') }}</span><span>{{ $t('cloud.trySearchOther') }}</span>
        </div>
      </div>
    </div>
    <!-- 绑定手机1  实名认证2 验证码3-->
    <BindPhone
      :isShow="isShowBindPhone"
      :type="bindType"
      :time="time"
      @getPhoneCode="getPhoneCode"
      @bindPhone="bindPhone"
      @confirmNameAuth="realNameAuth"
      @handleClose="isShowBindPhone = false"
    ></BindPhone>
      <!-- 取消收藏提示框 -->
      <TitleDialog
        ref="TitleDialogRef"
        :is-show="cloCollectShow"
        :title="collectObj.title"
        :text="collectObj.text"
        @handleClose="onCollectClose"
        @handleConfirm="onCollectConfirm"
      >
      </TitleDialog>
    <div class="toTop-div" v-show="showTop" @click="scrollToTop">
      <svg-btn icon-class="icon-toTop" style="width: 24px;height: 24px;"></svg-btn>
    </div>
  </div>
</template>

<script>
import {
  getCollectInfo,
} from "@/utils/apiList/home";
import {
  gathersCollList,
  setCollectInfo
} from "@/utils/apiList/set";
import { 
  delCollect 
} from "@/utils/apiList/downpage";
import {
  bindPhoneSendCode,
  bindPhoneAPI,
  realNameAuth,
} from "@/utils/apiList/account";
import TabList from "./TabList/index.vue";
import BindPhone from "@/components/Cloud/BindPhone";
import TitleDialog from "@/components/Dialog/TitleDialog.vue";
export default {
  components: {
    TabList,
    BindPhone,
    TitleDialog,
  },
  data() {
    return {
      screenList: [
        { id: 2, id1: 3, name: "按项目数排序" },
        { id: 0, id1: 1, name: "按时间排序" },
      ],
      screenId: 0,
      shareList: [], //分享列表
      allCheck: 0,
      checkedShares: [], //已选的分享数据
      total: 0,
      loading: true,
      shareParams: {
        //查询分享列表参数
        time: 1,//时间排序 时间排序1倒叙，0升序 默认1
        term: "",//项目数排序 项目数排序1倒叙，0升序 默认1
        limit: 60,
        keywords: "",
        page: 0,
        type: 0, //类型 0-全部 1-压缩包 2-视频 3-文档 4-图片 5-音频
      },
      boxType: false,
      handleItem: {}, //当前操作项
      isOver: false,
      showTop: false,
      isCancelShareTips: false,
      cancelParams: [],
      isShowBindPhone: false,
      bindType: 1, //
      msgid: "", //验证码id
      isbindphone: "", //是否绑定手机号
      timerA: null,
      time: 60,
      startX: 0,
      startY: 0,
      selectionBox: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      moveBox: {
        left: 0,
        top: 0,
      },
      mouseDownIsCheck: false,
      isLoading2: false,
      list1: [
        { id: 1, name: "Collection.View" },
        { id: 2, name: "share.copyLink" },
      ],
      showFlag: 0,
      isSelecting: 0,
      isOpen: false,
      isOpen2: true,
      isSelectMore: false,
      order: false,
      dropdown: ['contextmenu'],
      phone_item: {
        phone: "",
        pcode: "",
      },
      hoverIndex:'',//判断鼠标移入了哪个index
      cloCollectShow:false, //取消收藏弹窗是否显示
      collectObj: { //取消收藏弹窗文字内容
        title: "提示",
        text: "确定取消收藏该合集吗？",
      },
    };
  },
  created() {
    //获取收藏更新量
    this.getCollectNum()
    this.getShareList(1);
  },
  mounted() {
    this.$store.commit("setAttr", {
      name: "endSelectFuncallback",
      val: this.endSelectFun,
    });
  },
  watch: {
    checkedShares: {
      handler(newval, oldVal) {
        if (newval.length > 0 && newval.length < this.shareList.length) {
          this.allCheck = 1;
        } else if (newval.length == 0) {
          this.allCheck = 0;
        } else if (newval.length == this.shareList.length) {
          this.allCheck = 2;
        }
      },
      immediate: true,
    },
    pageValue(newVal) {
      if (newVal == 1) {
        this.cancelCheckedVal();
        this.scrollToTop();
      }
    },
  },
  methods: {
    //获取收藏更新量
    getCollectNum(){
      getCollectInfo().then(res =>{
        if(res.status == 1){
          this.$store.commit("setAttr", {
              name: "gathersNum",
              val: Number(res.data.gather),
          });
          this.$store.commit("setAttr", {
              name: "collectNum",
              val: Number(res.data.total),
            });
        }
      })
    },
    //更新最后查看时间
    setCollectInfo(row,index){
      if(row.is_update == 0){
        //如果是没有更新标签的情况下，就不需要请求接口
        return
      }
      setCollectInfo({
        collect_id:row.id,//收藏id
        type:1,//类型 1-合集 2-文件夹
      }).then(res =>{
        if(res.status == 1){
            this.shareList[index].is_update = 0
            this.getCollectNum()
        }else{
          this.$toast(res.msg, 2);
        }
      })
    },
    setNav(item) {
      this.$router.push({
        path: item.url,
      });
    },
    //排序
    changeSort(id) {
      this.screenId = id;
      this.checkedShares = [];
      switch (id) {
        case 0:
          this.shareParams.time = 1;
          this.shareParams.term = "";
          break;
        case 1:
          this.shareParams.time = 0;
          this.shareParams.term = "";
          break;
        case 2:
          this.shareParams.term = 1;
          this.shareParams.time = "";
          break;
        case 3:
          this.shareParams.term = 0;
          this.shareParams.time = "";
          break;
        default:
          break;
      }
      this.shareParams.page = 0;
      this.getShareList(1);
    },
    //搜索
    searchList(value) {
      this.shareParams.keywords = value;
      this.checkedShares = [];
      this.getShareList(1);
    },
    getShareList: _.debounce(
      function (type = 0) {
        if (type == 1) {
          this.shareParams.page = 0;
          this.isLoading2 = false;
          this.loading = true;
        } else {
          if (this.isOver) return;
          this.isLoading2 = true;
          this.loading = false;
        }
        this.isOver = false;
        this.shareParams.page += 1;
        gathersCollList(this.shareParams)
          .then((res) => {
            if (res.status == "1") {
              this.loading = false;
              this.isLoading2 = false;
              this.total = res.data.total;
              if (res.data.list.length < this.shareParams.limit)
                this.isOver = true;
                res.data.list.forEach((item, index) => {
                  item.isHover = false;
                  item.showPlay = true;
                });
              if (this.shareParams.page == 1) {
                this.shareList = res.data.list;
              } else {
                this.shareList = this.shareList.concat(res.data.list);
              }
              if (
                this.checkedShares.length > 0 &&
                this.checkedShares.length < this.shareList.length
              ) {
                this.allCheck = 1;
              } else if (this.checkedShares.length == 0) {
                this.allCheck = 0;
              } else if (this.checkedShares.length == this.shareList.length) {
                this.allCheck = 2;
              }
              this.dropdown = ['contextmenu']
            }
          })
          .catch((err) => {});
      },
      300,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //下拉框出现/隐藏时触发
    changeVisible(e, item) {
      item.isHover = e;
    },
  
    //更多 操作 a重命名 b取消分享
    handleCommand(command, item) {
      this.handleItem = item;
      this.isCancelShare(command);
    },
    // 单个点击选中
    chooseShare(index) {
      // 点击文件选中
      var exist = this.checkedShares.indexOf(index);
      if (exist == -1) {
        this.checkedShares.push(index);
      } else {
        this.checkedShares.splice(exist, 1);
      }
    },
    // 全选
    setCheckedVal() {
      // 点击文件选中
      this.checkedShares = [];
      var tmpfiles = this.shareList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        this.checkedShares.push(i);
      }
      this.allCheck = 1;
    },
    // 取消全选
    cancelCheckedVal() {
      this.allCheck = 0;
      this.checkedShares = [];
    },
    // 获取选中的文件的id
    getSelFileIds() {
      var ids = [];
      this.checkedShares.map((item) => {
        ids.push(this.shareList[item].gather_id);
      });
      return this.$utils.arrFilter(ids);
    },

    //取消收藏
    isCancelShare(row) {
      //多选
      if (this.checkedShares.length > 0) {
        //如果选中了某一项，且悬浮了其他项进行取消操作
        if(row){
          this.cancelParams = [this.handleItem.gather_id]
        }else{
          this.cancelParams = this.getSelFileIds();
        }
      }else{
        this.cancelParams = [this.handleItem.gather_id]
      }
      if(this.checkedShares.length > 1){
        this.collectObj.text = "确定取消收藏所选合集吗？"
      }else{
        this.collectObj.text = "确定取消收藏该合集吗？"
      }
      this.cloCollectShow = true;
    },
    //确定取消收藏弹窗
    onCollectConfirm(){
      delCollect({
        gathers_id:this.cancelParams,
      }).then(res =>{
        if(res.status == 1){
          this.onCollectClose()
          // this.$toast(res.msg, 1);
          this.checkedShares = [];
          this.getShareList(1);
        }else{
          this.$toast(res.msg, 2);
        }
      })
    },
    //关闭取消收藏弹窗
    onCollectClose() {
      this.cloCollectShow = false;
    },
    //查看文件
    viewUrl(item,index){
      //清除更新标志
      if(item.is_update == 1){
        this.setCollectInfo(item,index)
      }
      window.open(item.share_link,'_blank');
    },
    //复制链接
    copyUrl(item,index) {
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      //清除更新标志
      if(item.is_update == 1){
          this.setCollectInfo(item,index)
      }
      let msg =
        item.good_count == 1 ? "复制链接及取件码成功" : "复制链接成功";
      this.$copyText(item.share_link).then(
        (e) => {
          this.$toast(msg, 1);
        },
        function (e) {}
      );
    },
    
    scrollBoxFun() {
      var scrollEl = this.$refs.scrollBox;
      this.showTop = scrollEl.scrollTop > 50;
      let list = document.getElementsByClassName("el-tooltip__popper");
      let list1 = document.getElementsByClassName("el-dropdown-menu");
      if (list.length > 0) {
        list[list.length - 1].style.display = "none";
      }
      if(list1.length > 0){
        list1[list1.length - 1].style.display = "none";
      }
    },
    scrollToTop() {
      let scrollEl = this.$refs.scrollBox;
      scrollEl.scrollTop = 0;
    },
  
    getPhoneAly(captcha_verify) {
      return new Promise((resolve) => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
            }, 1000);
          } else if (res.status == 90001) {
            resolve({
                  result: false,
                  validate: false
                })
              this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.getPhoneAly);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    //发送验证码
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      };
      // var reg_tel =
      //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      // if (!reg_tel.test(phone)) {
      //     this.$toast("请正确输入手机号", 3);
      //     return true
      // }
      this.getPhoneAly();
    },
    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //实名
    realNameAuth(data) {
      realNameAuth(data).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    handleMouseDown: _.debounce(
      function (event, index , item) {
         //清除更新标志
        // if(item.is_update == 1){
        //   this.setCollectInfo(item,index)
        // }
        if(index == -1){
          this.checkedShares = []
        }
        this.selectionBox = {
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        };

        this.startX = event.clientX;
        this.startY = event.clientY;

        const currentX = event.clientX;
        const currentY = event.clientY;
        this.moveBox = {
          left: 0,
          top: 0,
        };
        // 检查触发事件的元素是否是 file-item-hover 的父元素
        if (event.button == 0) {
          let arr = ['icon_checks','iconColor']
          if (!arr.includes(event.target.classList[0])) {
            //点击icon_checks 即左侧选中多选不触发handleMouseDown 只触发chooseFile
            // 执行你的 mousedown 逻辑
            this.selectItemOne(index);
            this.moveBox = {
              left: currentX,
              top: currentY - 56,
            };
          }
        } else if (event.button == 1) {
        } else if (event.button == 2) {
          // this.checkedShares = [];
          if(index == -1) return;
          var exist = this.checkedShares.indexOf(index);
          if (exist == -1) {
            this.checkedShares = [];
            this.checkedShares.push(index);
          } else {
            // this.checkedShares.splice(exist, 1);
          }
          if (this.checkedShares.length > 1) {
            this.isSelectMore = true;
          } else {
            this.isSelectMore = false;
          }
        }
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    handleMouseenter(event, index) {
      this.hoverIndex = index
    },
    handleMouseleave(event, index){
      this.hoverIndex = ''
    },
    chooseFile(index) {
      // 点击文件选中
      var exist = this.checkedShares.indexOf(index);
      if (exist == -1) {
        this.checkedShares.push(index);
      } else {
        this.checkedShares.splice(exist, 1);
      }
    },

    selectItemOne(index) {
      if (index == -1) {
        this.isSelecting = 1;
        return;
      }
      // 点击文件选中
      var exist = this.checkedShares.indexOf(index);
      if (exist == -1) {
        this.checkedShares = [];
        this.checkedShares.push(index);
        this.isSelecting = 1;
      } else {
        // this.checkedShares.splice(exist, 1);
      }
    },
    selectItems: _.debounce(
      function (event) {
        const currentX = event.clientX;
        const currentY = event.clientY;

        // 计算选择框的位置和大小
        const minX = Math.min(this.startX, currentX);
        const minY = Math.min(this.startY, currentY);
        const maxX = Math.max(this.startX, currentX);
        const maxY = Math.max(this.startY, currentY);
        this.selectionBox = {
          left: minX,
          top: minY - 56,
          width: maxX - minX,
          height: maxY - minY,
        };
        if (this.isSelecting == 1) {
          // 遍历可选择项，判断是否在选择框内并选中
          this.shareList.forEach((item, index) => {
            const itemElement = document.querySelector(
              `.file-item-hover:nth-child(${this.shareList.indexOf(item) + 1})`
            );
            const itemRect = itemElement.getBoundingClientRect();
            if (itemRect.top > minY - 56 && itemRect.bottom < maxY + 69) {
              if (!this.checkedShares.includes(index)) {
                this.checkedShares.push(index);
              }
              this.showFlag = 1;
            } else {
              const index = this.checkedShares.indexOf(index);
              if (index !== -1) {
                this.checkedShares.splice(index, 1);
              }
            }
          });
        }
      },
      0,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    endSelection() {
      this.showFlag = 0;
    },
    endSelectFun() {
      this.isSelecting = 0;
      this.checkStr = "";
      this.showFlag = 0;
      this.$store.commit("setAttr", {
        name: "showFlag",
        val: 0,
      });
      this.$store.commit("setAttr", {
        name: "check_str",
        val: "",
      });
    },
    resetRouter() {
      this.shareParams={
        //查询分享列表参数
        time: 1,//时间排序 时间排序1倒叙，0升序 默认1
        term: "",//大小排序 大小排序1倒叙，0升序 默认1
        limit: 60,
        keywords: "",
        page: 0,
        type: 0, //类型 0-全部 1-压缩包 2-视频 3-文档 4-图片 5-音频
      },
      this.screenId = 0
      this.checkedShares=[]
      this.$refs.tabList.keyword = "";
      this.getShareList(1);
    },
    refresh() {
      this.shareParams.page = 0;
      this.checkedShares = [];
      this.getCollectNum()
      this.getShareList(1);
    },
    rightHandle(id, item,index) {
      this.visibleChange(false);
      switch (id) {
        case 1:
          //查看
          this.viewUrl(item,index);
          break;
        case 2:
          this.copyUrl(item,index);
          break;
        case 3:
          // 取消收藏
          this.isCancelShare();
          break;
        default:
          break;
      }
    },
    visibleChange(e) {
      if(e){
        this.dropdown = ['contextmenu','click']
        this.isOpen = e;
        this.isOpen2 = true
      } else {
        this.isOpen2 = false
        this.dropdown = ['contextmenu']
      }
    },
    onError(e, item) {
      this.$set(item, "showPlay", false);
    },
  },
  computed: {
    pageValue() {
      return this.shareParams.page;
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>
