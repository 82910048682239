<template>
  <div class="downPage">
    <div class="pageBox pageBoxheight">
      <div class="dp-filedownload">
        <span class="title">{{ $t('downPage.fileDownloadStatus') }}</span>
        <div class="download-progress">
          <div class="progress-box">
            <div class="file-info">
              <img class="icon" :src="fileInfo.ext_icon" />
              <div class="info">
                <span class="name">{{ fileInfo.fileName }}</span>
                <div class="time">
                  <span class="txt"
                    >{{ $utils.bytesToSize(fileInfo.currentSize) }} / {{
                      fileInfo.fileSize
                    }}</span
                  >
                  <span class="txt" v-if="fileInfo.fileStatus == 1"
                    >{{ fileInfo.dfcurrentSpeed }}{{ fileInfo.unit }} - 
                    {{ formatTime(fileInfo.surplusSecond) }}</span
                  >
                  <span class="txt" v-else>- -</span>
                  <span class="txt success" v-show="fileInfo.fileStatus == 2">{{ $t('downPage.downloadComplete') }}</span>
                  <span class="fail" v-show="fileInfo.fileStatus == 3"
                    >{{ $t('downPage.downloadError') }}</span
                  >
                  <fm-button
                      @click="retry($event)"
                       v-show="$store.state.userInfo.switchs?.auto_down_retry_switch_v2 == 0 && fileInfo.fileStatus == 3"
                      icon="icon-refresh"
                      round
                      size="mini"
                      > {{ $t('downPage.downloadRetry') }}</fm-button
                  >
                  <span class="txt" v-show="$store.state.userInfo.switchs?.auto_down_retry_switch_v2 != 1 && fileInfo.fileStatus == 1">{{
                    fileInfo.title
                  }}</span>
                  <span v-if="$store.state.userInfo.switchs?.auto_down_retry_switch_v2 == 1 && fileInfo.fileStatus == 1" class="retryDowblabel">{{ fileInfo.down_label }}</span>
                </div>
              </div>
              <div
                v-show="fileInfo.fileStatus == 1"
                class="bg-progress"
                :style="'width:' + fileInfo.downloadProgress + '%'"
              ></div>
            </div>
            <div
              v-show="fileInfo.fileStatus == 1"
              class="line"
              :style="'width:' + fileInfo.downloadProgress + '%'"
            ></div>
          </div>
        </div>
        <!-- 开通会员 -->
        <!-- <span class="title">开通会员</span> -->
        <!-- <VipBenefits style="max-width: 1700px" ref="VipBenefits"></VipBenefits> -->
        <PayOrderVip style="margin-top: 40px;" @allQuan="PayJurisisShow = true"></PayOrderVip>
        <PayJuris
        :isShow="PayJurisisShow"
        @handleClose="PayJurisisShow = false"
        ></PayJuris>
        <!-- 支付弹框 -->
        <PayOrder
          ref="PayOrder"
          :isShow="isShowPayOrder"
          :payData="payData"
          :showBankInfo="showBankInfo"
          :allMember="allMember"
          :superSlideData="superSlideData"
          :upvipinfo="upvipinfo"
          :routeIds="$store.state.route_ids.join('-')"
          :voucher="voucherTExt"
          @clearCoupon="clearCoupon"
          @handleClosePayDia="handleClosePayDia"
          @openSuccess="openSuccess"
        ></PayOrder>
        <!-- 优惠码 -->
        <DiscountCodeBox
          ref="DiscountCodeBox"
          :isShow="showDiscountCode"
          @handleClose="handleCloseDis"
          @checkCoupe="couponValidate"
        ></DiscountCodeBox>
      <!-- 补差价升级说明 -->
      <Upgradeexplain
        ref="Upgradeexplain"
        :isShow="showUpgradeexplain"
        :upvipinfo="upvipinfo"
        @handleClose="handleCloseUpgrade"
      ></Upgradeexplain>
        <SuccessfullyModel
          :payToast="payToast"
          :isShowModel="showSuccessfullyModel"
          @successRefresh="successRefresh"
        ></SuccessfullyModel>
      </div>
    </div>
  </div>
</template>

<script>
import VipBenefits from "@/components/VipBenefits/index";
import TheSpeed from "../../utils/upload/speedometer";
import PayOrder from "@/components/PayOrder/index";
import DiscountCodeBox from "@/components/Member/DiscountCodeBox.vue";
import SuccessfullyModel from "@/components/Member/SuccessfullyModel.vue";
import Upgradeexplain from "@/components/Member/Upgradeexplain.vue";
import VIPPAYMIXIN from "@/mixin/vip.vue";
import { getDownFileInfo } from "@/utils/apiList/downpage";
import {  autoRetryStatAPI } from "@/utils/apiList/home";

import {downloadFile} from "@/utils/apiList/cloud";
import {downFailureStat, remoteAuthLog} from "@/utils/apiList/log"
import OneUpload from "@/utils/upload/newUpload";
import { EventBus } from './EventBus.js';
import PayOrderVip from "@/components/PayOrderVip/index";
import PayJuris from "@/components/PayJuris/index";

export default {
  mixins: [VIPPAYMIXIN],
  data() {
    return {
      fileInfo: {},
      code: "",
      sc: "", //屏幕点击位置
      ms: "", //屏幕点击位置
      error_params:{},
      newEvents:[],
      updateFlag:false,
      voucherTExt: '',
      networkType: '4g',
      retryFlag: true,
      dcFlag:0,
      PayJurisisShow: false
    };
  },
  components: {
    VipBenefits,
    PayOrder,
    DiscountCodeBox,
    SuccessfullyModel,
    Upgradeexplain,
    PayOrderVip,
    PayJuris
  },
  created() {
    document.addEventListener('visibilitychange', this.handleVisiable)
    this.code = this.$route.params.code;
      this.gopageA1();
    if (this.$store.state.downloadFile.url) {
      this.goDownload(this.$store.state.downloadFile);
    } else {
      this.fileInfo = this.$store.state.downloadFile;
    }
    this.networkType = this.$utils.getConnection()?.effectiveType || '4g';
  },
  beforeDestroy() {
    if(this.fileInfo.fileStatus == 1){//切换路由、中止下载任务
      this.fileInfo.xmlHttp.abort();
    }
  },
  computed: {
    part_size() {
      let _userInfo = this.$store.state.userInfo || {};
      return Number(_userInfo.upload_part_size) || 2 * 1024 * 1024;
    },
  },
  inject: ['grandParentData'],
  mounted(){
    EventBus.$on('updateData', (newData) => {
      // 处理数据更新
      this.newEvents = newData
    });
  },
  destroyed() {
    document.removeEventListener("visibilitychange",this.handleVisiable);
  },
  methods: {
    handleVisiable(e){
      switch (e.target.visibilityState) {
        case 'visible':
           this.$store.commit("setAttr", {
             name: "route_ids",
             val: ['A1'],
           })
          this.$utils.routeModeReported('B3',this.voucherTExt);
          break
        case 'hidden':
          break
      }
    },
    //==========================
    formatTime(input) {
      if (typeof input === "string") {
        const matches = input.match(/(\d+)小时(\d+)分钟(\d+)秒/);
        if (matches) {
          let hours = parseInt(matches[1]).toString().padStart(2, "0");
          let minutes = parseInt(matches[2]).toString().padStart(2, "0");
          let seconds = parseInt(matches[3]).toString().padStart(2, "0");
          return `${hours}:${minutes}:${seconds}`;
        } else {
          return "";
        }
      } else {
        return "00:00:00";
      }
    },
    goDownload(opt) {
      this.fileInfo = {
        title: opt.title,
        ext_icon: opt.ext_icon,
        fid: opt.fid,
        type: opt.fileType,
        fileName: opt.fileName,
        fileSize: opt.fileSize, //文件大小
        downloadProgress: 0, //下载进度
        currentSize: 0, //当前已下载大小
        surplusSecond: 0, //剩余下载时间
        dfcurrentSpeed: 0, //当前下载速度
        unit: 'B/s',
        surplusSize: 0, //剩余下载大小
        lastDataSize: 0, //上一秒加载的大小
        size: 0, //跟上一秒的差值
        spLength: 0,
        fileStatus: 0,
        xmlHttp: null,
        down_id:opt.down_id,
        down_token: opt.down_token,
        is_remote_record: opt.is_remote_record || '',
        timeId:0,
        url:opt.url,
        isRetry: opt.isRetry ? opt.isRetry : 0,
        itemData: [],
        isClickFlag: opt.isClickFlag,
        retry_reason: 4,
        ischangeLine: 0,//是否换线
        down_label: opt.down_label,
        is_vip_down: opt.is_vip_down,
        dc: 0,
        isDownIng:0
      };
      this.downloadfile(opt.url, opt.fileName, "application/octet-stream");
    },
    downloadfile(url, strFileName, strMimeType) {
      var that = this;
      var xmlHttp = null;
      if (window.ActiveXObject) {
        // IE6, IE5 浏览器执行代码
        xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
      } else if (window.XMLHttpRequest) {
        // IE7+, Firefox, Chrome, Opera, Safari 浏览器执行代码
        xmlHttp = new XMLHttpRequest();
      }
      that.fileInfo.fileStatus = 1;
      that.fileInfo.xmlHttp = xmlHttp;
      that.startInterval(that.fileInfo, xmlHttp);
      //2.如果实例化成功，就调用open（）方法：
      if (xmlHttp != null) {
        that.remoteAuthLogFn({down_token: that.fileInfo.down_token, status: 2}, that.fileInfo.is_remote_record) // 远程鉴权发起请求
        xmlHttp.open("get", url, true);
        // 添加自定义请求头
        xmlHttp.setRequestHeader("remote_auth_token", that.fileInfo.down_token); // 添加自定义请求头
        xmlHttp.responseType = "blob"; //关键
        xmlHttp.send();
        xmlHttp.onreadystatechange = doResult; //设置回调函数
        that.remoteAuthLogFn({down_token: that.fileInfo.down_token, status: 3}, that.fileInfo.is_remote_record) // 远程鉴权开始下载
      }
      function doResult(e) {
        if (xmlHttp.readyState == 4) {
          //4表示执行完成
          if (xmlHttp.status == 200) {
            //200表示执行成功
            if (window.download) {
              window.download(xmlHttp.response, strFileName, strMimeType);
            }
            that.error_params = {
              http_code: that.fileInfo.xmlHttp.status,//http状态码，跨域或者拿不到情况填10000
              fail_reason: '',//错误原因，若trycatch 能捕获到 填写详细信息，
              status: 1,//1-链接下载成功 2- 链接下载失败 4-其他原因失败
              down_url: that.fileInfo.url,//下载失败的url 若 fail_subject 为1此字段非必传
              down_id: that.fileInfo.down_id,
              bs_tag: localStorage.getItem('bs_tag'),
              down_origin: that.fileInfo.title == that.$t('downPage.memberDownTitStr') ? 4 : 3,// 3-下载页普通下载 4-下载页会员下载
            }
            that.clickaa('success')
            that.fileInfo.fileStatus = 2;
            that.fileInfo.dfcurrentSpeed = 0;
            that.fileInfo.isDownIng = -1; //成功
            that.fileInfo.unit = 'B/s';
            that.remoteAuthLogFn({down_token: that.fileInfo.down_token, status: 5}, that.fileInfo.is_remote_record) // 远程鉴权下载成功
            if (that.fileInfo.theSpeed) {
              that.fileInfo.theSpeed.clearSpeed();
              that.fileInfo.theSpeed = null;
            }
            
            let obj = {
              record_type: that.dcFlag== '0' ? 1 : 6,
              network: that.networkType,
              down_id: that.fileInfo.down_id,
              url: that.fileInfo.url,
              success_type: that.fileInfo.retry_reason,
              dc: that.dcFlag,
            }
            autoRetryStatAPI(obj).then((res) => { });
          } else {
            that.error_params = {
              http_code: that.fileInfo.xmlHttp.status == 0 ? 10000 : that.fileInfo.xmlHttp.status,//http状态码，跨域或者拿不到情况填10000
              fail_reason: that.fileInfo.xmlHttp.statusText,//错误原因，若trycatch 能捕获到 填写详细信息，
              status: that.fileInfo.xmlHttp.status == 0 ? 4 : 2,//1-链接获取失败 2- 链接下载失败 3-保存文件失败 4-其他原因失败
              down_url: that.fileInfo.url,//下载失败的url 若 fail_subject 为1此字段非必传
              down_id: that.fileInfo.down_id,
              bs_tag: localStorage.getItem('bs_tag'),
              down_origin: that.fileInfo.title == that.$t('downPage.memberDownTitStr') ? 4 : 3,// 3-下载页普通下载 4-下载页会员下载
            }
            that.fileInfo.isRetry == 1 ? that.clickaa('success') : that.clickaa('error')//重试不录制
            //获取录屏信息
            that.fileInfo.fileStatus = 3;
            that.fileInfo.dfcurrentSpeed = 0;
            that.fileInfo.unit = 'B/s';
            if (that.fileInfo.theSpeed) {
              that.fileInfo.theSpeed.clearSpeed();
              that.fileInfo.theSpeed = null;
            }
            if (that.fileInfo.retry_reason == 4 && that.fileInfo.isDownIng > 0) {
              if (that.$store.state.userInfo.switchs?.auto_down_retry_switch_v2 == 1) {
                if (xmlHttp.status != 200 && xmlHttp.status != 0) {
                  let obj = {
                    record_type: that.dcFlag !='MAX' ? 3:7,//埋点类型 1-直接成功日志 2-下载过慢日志 3-下载失败日志 6-最终成功日志 7-最终失败日志 
                    network: that.networkType,
                    down_id: that.fileInfo.down_id,
                    url: that.fileInfo.url,
                    dc: that.dcFlag,
                  }
                  autoRetryStatAPI(obj).then((res) => {
                    if (res.status) {
                      var e = window.event;
                      that.fileInfo.retry_reason = 5;
                      that.retry1(that.fileInfo);
                    }
                  });
                }
               

              }
            } else {
              if (that.$store.state.userInfo.switchs?.auto_down_retry_switch_v2 == 1) {
                let obj = {
                  record_type: that.dcFlag != 'MAX' ? 3 : 7,//埋点类型 1-直接成功日志 2-下载过慢日志 3-下载失败日志 6-最终成功日志 7-最终失败日志 
                  network: that.networkType,
                  down_id: that.fileInfo.down_id,
                  url: that.fileInfo.url,
                  dc: that.dcFlag,
                }
                that.remoteAuthLogFn({down_token: that.fileInfo.down_token, status: 4}, that.fileInfo.is_remote_record) // 远程鉴权下载失败
                autoRetryStatAPI(obj).then((res) => {
                  if (res.status) {
                    var e = window.event;
                    that.fileInfo.retry_reason = 5;                  
                    that.retry1(that.fileInfo);
                  }
                });
              }
            }
            that.fileInfo.isDownIng = 0; //失败
          }
        }
      }
    },
    getEv(){
      // setTimeout(() => {
        if(this.newEvents.length==0){
          this.downFailureStat()
          return
        }
        var jsonString = JSON.stringify(this.newEvents);
        // 创建一个 Blob 对象
        var file = new Blob([jsonString], { type: 'application/json' });
        let _file = file;
        let opt = {
            id:'',
            name: '',
            prdMd5: file.prdMd5,
            md5Flag:'',
            fileStatus: '',
            size: file.size,
            file: file,
            currentSpeed: 0,
            loaded: 0,
            folderid: '',
            folderName: '',
            sizeM1: this.part_size,
            $store: this.$store,
        };
        _file = new OneUpload(opt, this.upCallback);
        this.newComputeMD5(_file)
      // }, 100);
    },
    async newComputeMD5(file) {
      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;
      let hashAll = "";
      let _flag = true;
      for (let i = 0; i < file.part_count; i++) {
        let _blob;
        if (i === file.part_count - 1) {
          _blob = blobSlice.call(file.file, i * file.sizeM1, file.size);
        } else {
          _blob = blobSlice.call(
            file.file,
            i * file.sizeM1,
            (i + 1) * file.sizeM1
          );
        }
        try {
          let _md5 = await this.handleReadFile(_blob);
          hashAll += this.hexToBinaryString(_md5);
        } catch (e) {
          _flag = false;
          break;
        }
      }

      if (!_flag) {
        file.prdMd5 = "";
        return;
      }

      file.md5Flag = 2;

      let md5 = SparkMD5.hashBinary(hashAll);
      file.prdMd5 = md5;
      file.uniqueIdentifier = md5;
      this.downFailureStat(file);
    },
    handleReadFile(_blob) {
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer();
        fileReader.onloadend = (e) => {
          spark.append(e.target.result);
          resolve(spark.end());
        };
        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(_blob);
      });
    },
    hexToBinaryString(hex) {
      let bytes = [],
        length = hex.length,
        x;
      for (x = 0; x < length - 1; x += 2) {
        bytes.push(parseInt(hex.substr(x, 2), 16));
      }
      return String.fromCharCode.apply(String, bytes);
    },
    //上报下载错误信息
    downFailureStat(file){
      downFailureStat({total_size:file ? file.size:'',hash:file ? file.prdMd5 :'',...this.error_params}).then((res) => {
        if(res.data.is_record!='0'){
          file.task_id =  res.data.task_id;
          file.merge_part_url = res.data.merge_part_url;
          file.upload_part_url =  res.data.upload_part_url;
          file.cancel_url =  res.data.cancel_url;
          file.resume();
        }
        this.fileInfo.url = "";
        this.$store.commit("setAttr", {
          name: "downloadFile",
          val: this.fileInfo,
        });
      })
    },
    // 远程鉴权埋点
    remoteAuthLogFn(params, is_remote_record = 0) {
      if(is_remote_record == 1){
        remoteAuthLog(params).then((res) => {});
      }
    },
    upCallback(args) {
      let res = args.res || { data: { path: "" } };
      this.upDis = false;
      if (args.action === "success") {
        this.logo = this.$store.state.userInfo.ucgimg_host + res.data.path;
        this.upAvater = res.data.path;
      } else if (args.action === "fail") {
        this.$toast(args.msg, 2);
      } else if (args.action === "stepFail") {
        this.$toast("头像上传失败，请重试", 2);
      }
    },
    startInterval(obj, xmlHttp) {
      let that = this;
      obj.theSpeed = new TheSpeed();
      obj.theSpeed.initSpeed((_speed) => {
        const timeMax = +that.$store.state.userInfo?.auto_retry_params?.times || 10;
        const bytesMax = +that.$store.state.userInfo?.auto_retry_params?.bytes || 307200;
       

        if (obj?.dfcurrentSpeed >= 0) {
           
          if (_speed >= 1024 * 1024 * 1024) {
            obj.unit = 'GB/s';
            obj.dfcurrentSpeed = (_speed / 1024 / 1024 / 1024).toFixed(2); // Update current speed
          } else if (_speed >= 1024 * 1024) {
            obj.unit = 'MB/s'
            obj.dfcurrentSpeed = (_speed / 1024 / 1024).toFixed(2); //当前速度
          } else {
            if (_speed == 0) {
              obj.unit = 'B/s';
              obj.dfcurrentSpeed = (_speed / 1024); //当前速度
            } else {
              obj.unit = 'KB/s';
              obj.dfcurrentSpeed = (_speed / 1024).toFixed(2); //当前速度
            }

          }
          if (_speed < bytesMax) {
            obj.itemData.push({
              speed: _speed,
            });
          } else {
            obj.itemData = []
          }
          if(this.$store.state.userInfo.switchs?.auto_down_retry_switch_v2 == 1 && obj.itemData.length == timeMax && obj.is_vip_down == 1 && that.dcFlag != 'MAX') {
            // obj.itemData = []
            // obj.retry_reason = 4;
           let obj = {
              record_type: 2, //埋点类型 1-直接成功日志 2-下载过慢日志 3-下载失败日志 6-最终成功日志 7-最终失败日志 
              network: that.networkType,
              down_id: that.fileInfo.down_id,
              url: that.fileInfo.url,
              dc: that.dcFlag,
           }
            autoRetryStatAPI(obj).then((res) => {
              if (res.status) {
                if (that.retryFlag) {
                  var e = window.event;
                  that.retry1(e)
                }
              }
             });
            
          }
        }
        if (
          obj &&
          obj.surplusSecond !== undefined &&
          obj.surplusSecond !== null
        ) {
          obj.surplusSecond = that.$utils.formatSeconds(
            (obj.surplusSize ? obj.surplusSize : 0) / _speed
          ); //剩余时间
          obj.size = 0;
        }
        // if(obj.timeId < 15){//李总说 注释掉
        //       obj.timeId += 1; 
        // }else{
        //     if(this.$utils.bytesToSizePagec(obj.currentSize)){
        //         obj.timeId = 0; 
        //         obj.xmlHttp.abort();
        //         obj.fileStatus = 3
        //     }
        // }
      });
      xmlHttp.addEventListener(
        "progress",
        function (evt) {
          if (evt.lengthComputable) {
            if (obj) {
              let transfer = evt.loaded - obj.currentSize;
              obj.theSpeed.spLength += transfer;
              obj.currentSize = evt.loaded;
              let percentComplete = evt.loaded / evt.total; //获取进度
              obj.downloadProgress = (percentComplete * 100).toFixed(1);
              obj.surplusSize = evt.total - evt.loaded; //剩余大小
              let sizeModify = evt.loaded - obj.lastDataSize; //避免网络不行出现负数。。
              if (sizeModify) {
                obj.size += sizeModify;
              }
              obj.isDownIng += 1;
              obj.lastDataSize = evt.loaded;
            } else {
            }
          }
        },
        false
      );
    },
    retry(e) {
      e = e || window.event;
      this.ms = e.clientX + "*" + e.clientY;
      this.sc = window.screen.width + "*" + window.screen.height;
      // this.$store.state.downloadFile.title = "极速下载中"
      //   ? this.svipDown()
      //   : this.downByPoint();
      this.retryDownload()
    },
    retry1: _.debounce(
      function (e) {
        e = e || window.event;
        if(e.clientX && e.clientY ){
          this.ms = e?.clientX + "*" + e?.clientY;
        }else{
          this.ms = "400*400"
        }
        this.sc = window.screen.width + "*" + window.screen.height;
        // this.$store.state.downloadFile.title = "极速下载中"
        //   ? this.svipDown()
        //   : this.downByPoint();

        this.fileInfo.ischangeLine += 1
        this.retryDownload()
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    retryDownload: _.debounce(
      function () {
        let params={
          url: this.code, 
          type:4,// 1:列表下载，2:福利点下载，3:vip下载，4：重试
          // retry_url: this.$store.state.downloadFile.url, 
          ms: this.ms, 
          sc: this.sc,
          down_id: this.$store.state.downloadFile.down_id,
          network: this.networkType,
          retry_reason:this.fileInfo.retry_reason
        }
      //重试
        downloadFile(params).then((res) => {
          if (res.status == '1') {
            let fileName = `${res.data.file.name}${res.data.file.extension}`;
            let obj = {
              fid: res.data.file.id,
              url: res.data.url,
              fileName: fileName,
              fileSize: res.data.file.size, //文件大小
              fileType: res.data.file.extension, 
              ext_icon: res.data.file.ext_icon,
              title: this.$store.state.downloadFile.title,
              down_id: res.data.down_id,//下载id，重试时需要用到
              isRetry: 1,
              rebate_model_voucher: this.fileInfo.rebate_model_voucher,
              down_label: res.data.down_label,
              is_vip_down: res.data.is_vip_down,
              dc:res.data.dc,
              down_token: res.data.down_token,
              is_remote_record: res.data.is_remote_record
            };
            this.retryFlag = true;
            this.dcFlag = res.data.dc;
            this.fileInfo.xmlHttp.abort();
            this.fileInfo.fileStatus = -1;
            this.$store.commit("setAttr", { name: "downloadFile", val: obj });
            this.fileInfo.isClickFlag = 0;
            setTimeout(() => {
              this.goDownload(this.$store.state.downloadFile); 
            }, 0);
          } else if (res.status == '20004') {
            this.$router.push({
              path: res.data.go_back,
            });
          } else if (res.status == "83001") {
            window.location.reload()
          } else if (res.status == '60019') {
            this.retryFlag = false
          } else if (res.status == 0) {//50006锁定 60001删除 60013 过期
            this.retryFlag = false;
            if (this.$store.state.userInfo.switchs?.auto_down_retry_switch_v2 !=1) { 
              this.$toast(res.msg, 2);
            } else {
              this.fileInfo.xmlHttp?.abort();
              this.fileInfo.fileStatus = 3;
              this.fileInfo.xmlHttp = "";
              
              // let obj = {
              //   record_type: 7,
              //   network: this.networkType,
              //   down_id: this.fileInfo.down_id,
              //   url: this.fileInfo.url,
              //   success_type:this.fileInfo.retry_reason
              // }
              // autoRetryStatAPI(obj).then((res) => {});
            }
          } else {
            this.$toast(res.msg, 2);
          }
        })
      },
      300,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //普通下载重试
    downByPoint() {
      downByPoint({ url: this.code, retry: 1, ms: this.ms, sc: this.sc }).then(
        (res) => {
          if (res.status == "1") {
            let fileName = `${res.data.name}${res.data.extension}`;
            let obj = {
              fid: res.data.id,
              url: res.data.url,
              fileName: fileName,
              fileSize: res.data.size, //文件大小
              fileType: res.data.extension,
              ext_icon: res.data.ext_icon,
              title: this.$t('downPage.touristDownTitStr'),
              down_token: res.data.down_token,
              is_remote_record: res.data.is_remote_record
            };
            this.$store.commit("setAttr", { name: "downloadFile", val: obj });
            this.goDownload(this.$store.state.downloadFile);
          } else if (res.status == "20004") {
            this.$router.push({
              path: res.data.go_back,
            });
          } else {
            this.$toast(res.msg, 2);
          }
        }
      );
    },
    //会员重试
    svipDown() {
      svipDown({ code: this.code, retry: 1, ms: this.ms, sc: this.sc }).then(
        (res) => {
          if (res.status == "1") {
            let fileName = `${res.data.name}${res.data.extension}`;
            let obj = {
              fid: res.data.id,
              url: res.data.url,
              fileName: fileName,
              fileSize: res.data.size, //文件大小
              fileType: res.data.extension,
              ext_icon: res.data.ext_icon,
              title: this.$t('downPage.memberDownTitStr'),
              down_token: res.data.down_token,
              is_remote_record: res.data.is_remote_record
            };
            this.$store.commit("setAttr", { name: "downloadFile", val: obj });
            this.goDownload(this.$store.state.downloadFile);
          } else if (res.status == "20004") {
            this.$router.push({
              path: res.data.go_back,
            });
          } else {
            this.$toast(res.msg, 2);
          }
        }
      );
    },
    goVip() {
      this.$utils.routeModeReported('C15',this.voucherTExt);
      window.open("/vip");
    },
    //获取文件信息

    refreshPage() {
      this.$store.commit("getuserVuex");
    },
    gopageA1() {
      getDownFileInfo({ url: this.code }).then((res) => {
        if (res.status == "1") {
          this.$utils.routeModeReported('B3',res.data.voucher);
          this.voucherTExt = res.data.voucher
          if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
            this.$router.push({
              path: "/s/" + res.data.fshort,
            });
          }
        }else if(res.status=='20004'){
          this.$router.push({
            path: res.data.go_back,
          });
        }else if(res.status=='60001'){
          this.$toast(res.msg,2)
        }
      });
    },
    gopageA() {},
    clickaa(type){
      if(type=='error'){//失败计算视频md5 上传文件
        setTimeout(()=>{
          this.$store.commit('onEndRecord')
          this.getEv()
        },1000)
      }else{//成功 上报接口
        this.$store.commit('onEndRecord')
        this.downFailureStat()
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.retryDowblabel{
  font-weight: 400;
font-size: 12px;
color: #F67942;
line-height: 20px;
}
</style>
