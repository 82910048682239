<template>
    <el-dialog
      title="提示"
      :visible.sync="isShow"
      width="480px"
      custom-class="tipDialog"  :append-to-body="true"  :close-on-click-modal="false">
      <div class="dialog-body">
          <div class="d-header">
              <div class="left">
                  <img :src="$utils.getPng('web4/icon-circle-fill')"/>
                  <span>{{ delTipsType==1 ? '彻底删除' : '清空回收站' }}提示</span>
              </div>
              <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
          </div>
          <div class="d-content">
              <span v-show="statusType == 1">
                 {{delTipsType==1 ? '所选文件将彻底删除且无法找回' : '回收站文件将彻底删除且无法找回'}} 
              </span>
              <span v-show="statusType == 2">
                 {{delTipsType==1 ? '所选合集将彻底删除且无法找回' : '回收站合集将彻底删除且无法找回'}} 
              </span>
          </div>
          <div class="d-footer">
            <fm-button @click="confirm" type="primary" size="small" >彻底删除</fm-button>
            <fm-button @click="handleClose"  type="info" plain size="small">取 消</fm-button>
          </div>
      </div>
      </el-dialog>
  </template>
  
  <script>
  export default {
      name:'base',
      props:{
          isShow: {
              type: Boolean,
              default: false
          },
          delTipsType:{
              type:Number,
              default:1
          },
          statusType:{
            type:Number,
            default:1
          },  
      },  
      methods:{
          handleClose(){
              this.$emit('handleClose')
          },
          confirm(){
              this.$emit("confirmDel")
          }
      }
  }
  </script>
  
  <style lang="scss">
      @import "../Dialog/dialog.scss";
  </style>
