import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data) {
  if (data?.ak) {
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  } else {
    return data;
  }
}

function inParameter(data) {
  if (data) {
    return { sn: ed, jt: utils.secret(data, ak)};
  } else {
    return data;
  }
}

export function getIndex() {
  return request({
    url: "/user/index",
    method: "get",
  }).catch((err) => {});
}
export function gethotApi(data) {
  return request({
    url: "/cloud/indexHotTrendsHome",
    method: "post",
    data,
  }).catch((err) => {
    console.log(err);
  });
}


export function filelistv2(data) {
  return request({
    url: "file/filelistv2",
    method: "post",
    data,
  }).catch((err) => {});
}

//新增埋点数据
export function newReportedAPI(data) {
  return request({
    url: "/buryLog/reported2",
    method: "post",
    data,
  }).catch((err) => {});
}

//来路模式埋点数据
export function attributionPathLogAPI(data) {
  return request({
    url: "/disk-service/attributionPathLog/log",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}
//下载埋点
export function autoRetryStatAPI(data) {
  return request({
    url: "/disk-service/stat/autoRetryStatV2",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}
  

//获取用户信息
export function getUserInfoAPI(data) {
  return request({
    url: "/user-service/user/info",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}
//获取下载链接
export function clientDownUrlAPI(data) {
  return request({
    url: "/user-service/common/clientDownUrl",
    method: "post",
    data,
  }).catch((err) => {});
}

//首页 获取评论和订阅号头像
export function getHomeIndex(data) {
  return request({
    url: "/user-service/common/homeIndex",
    method: "post",
    data,
  }).catch((err) => {});
}

//h5获取滑块展示信息
export function getSliderInfoAPI(data) {
  return request({
    url: "/validate-service/slider/info",
    method: "post",
    data,
  }).catch((err) => {});
}
//获取触发安全验证后二维码内容
export function generateRiskQrcodeAPI(data) {
  return request({
    url: "/user-service/common/generateRiskQrcode",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}
//查询本机验证二维码状态
export function queryQrcodeAPI(data) {
  return request({
    url: "/user-service/common/queryQrcode",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}
//首页查询是cookie 是否过期
export function checkLoginFlag(data) {
  return request({
    url: "/user-service/user/getLoginStatus",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {});
}

//个人信息悬浮窗
export function getWebUserVipInfoAPI(data) {
  return request({
    url: "/user-service/member/getWebUserVipInfo",
    method: "post",
    data: inParameter(data),
  })
  .then((res) => {
    res.data = decrypt(res.data);
    return res;
  })
  .catch((err) => {});
}
// 获取收藏更新量
export function getCollectInfo(data) {
  return request({
    url: "/disk-service/file/collectInfo",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
